/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-13",
    versionChannel: "nightly",
    buildDate: "2023-06-13T03:01:33.033Z",
    commitHash: "c4e4eba63f5e2f7a24b598eec7fa560c241212d0",
};
